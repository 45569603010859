import { useState, useEffect, useContext } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import cookie from "js-cookie";
import { PromotionContext } from '../../pages/_app';
import FormCard from '../shared/FormCard';
import MobileButton from '../shared/MobileButton';
import dynamic from 'next/dynamic';
import LandingLayout from '../shared/LandingLayout';

const Main = ({ setIsOpen, visibility }) => {
    const Loader = dynamic(() => import('../Hooks/Loader'))
    let { promo, details } = useContext(PromotionContext);
    const [counterUp, setCounterUp] = useState(5690);
    const router = useRouter();
    const randomNumber = max => Math.floor(Math.random() * max);
    const paramsCookieName = "fixat_utm_params";
    const [signupLoading, setSignupLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(router.query).length === 0) return;
        const paramsAsString = router.asPath;

        cookie.set(paramsCookieName, decodeURI(paramsAsString), {
            expires: 1
        });
    }, [router]);

    useEffect(() => {
        if (counterUp < 5980) {
            const interval = setInterval(() => {
                const res = randomNumber(2) === 0 ? counterUp - randomNumber(20) : counterUp + randomNumber(20);
                setCounterUp(res);
            }, 6000);

            return () => clearInterval(interval);
        }
    }, [counterUp]);

    useEffect(() => {
        const desktopBtn = document.querySelector('#asesoria_button');
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 580 || document.documentElement.scrollTop > 580) {
                desktopBtn.style.visibility = 'visible';
                desktopBtn.classList.remove('opacity-0');
                desktopBtn.classList.add('opacity-100');
            } else {
                desktopBtn.style.visibility = 'hidden';
                desktopBtn.classList.add('opacity-0');
                desktopBtn.classList.remove('opacity-100');
            }
        });
    }, []);

    const footerSection = () => (
        <div className="w-full flex flex-col items-center md:flex-row z-30">
            <div className="md:w-1/2 w-full">
                <div className="xl:w-9/12 text-center">
                    <div className="xs:pt-0 md:pt-24 lg:pt-12">
                        <h1 className="font-fredoka mt-6 tracking-wide text-3xl">
                            Si das factura o <br></br> recibos de honorarios,<br></br>FIXAT® ES PARA TI
                        </h1>
                    </div>
                </div>
            </div>
            <div className="md:w-1/2 w-full text-center">
                <div className="">
                    <div className="w-full">
                        <div className="flex w-full max-w-xs text-left mx-auto py-8 md:my-5 lg:my-0 pb-12">
                            <span className="shadow-shadow-inset text-2xl text-secondary font-medium text-center float-left bg-white leading-8 w-16 rounded-lg  px-2 mr-3">
                                {counterUp}
                            </span>
                            <span className="text-secondary">Personas en línea ahorrando impuestos con FIXAT®
                            </span>
                        </div>
                    </div>
                    <ul className="check_list">
                        <li><span>Adiós a los trámites confusos</span></li>
                        <li><span>Evita multas y pagos excesivos</span></li>
                        <li><span>Deduce impuestos y ahorra dinero</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

    return (
        <>
            <MobileButton setIsOpen={setIsOpen} />
            <section className={`${(promo && visibility && details?.show_banner) ? 'pt-20' : 'pt-0'} mmd:pt-3 w-full transition ease-in-out duration-300 relative bg-paste-blue`} id="main_top">
                <div className="xs:hidden md:block absolute top-0  w-full lg:h-[950px]">
                    <Image
                        src="https://cdn.fixat.mx/assets/home/bg/webp/bg_001.webp"
                        alt="Fondo de fórmula fixat"
                        objectFit="cover"
                        layout="fill"
                        quality={100}
                        loading="lazy"
                    />
                </div>
                {signupLoading && (<Loader title='' description="Tu registro se está procesando,  pronto tendrás la tranquilidad fiscal que buscas" />)}
                <div className={`w-11/12 pl-16 2xl:pl-28 absolute ${(promo && details?.show_banner && visibility) ? 'top-32 mmd:top-20' : 'top-20'}`}>
                    <div className=" w-full lg:w-1/2 align-center">
                        <div className={`flex justify-center top-0`}>
                            <div className='lg:relative w-full ssm:pl-5 xs:h-[100px] lg:h-[140px]'>
                                <Image
                                    src="https://cdn.fixat.mx/assets/home/img/avion-declara-impuestos-sin-complicaciones.png"
                                    alt="Declara tus impuestos !Sin complicaciones!"
                                    title="Declara tus impuestos !Sin complicaciones!"
                                    layout='fill'
                                    objectFit="contain"
                                    fetchpriority="high"
                                    loading='eager'
                                />
                            </div>
                        </div>
                        <div className="w-0 md:w-2/3 2xl:pl-36">
                            <div className='relative hidden  lg:block w-[368px] h-[590px]'>
                                <Image
                                    src="https://cdn.fixat.mx/assets/home/img/fixi-saludando.png"
                                    alt="Fixi saludando desde el formulario principal"
                                    title="Fixi"
                                    layout='fill'
                                    objectFit="contain"
                                    loading='eager'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${promo ? 'lg:max-h-[750px]' :' lg:max-h-[670px]'} mt-40 mmd:mt-36 lg:mt-0`}>
                    <LandingLayout background={"bg-transparent"} promo={promo} details={details} visibility={visibility} id={'main'}>
                        <>
                            <div className='hidden md:flex w-full md:justify-center lg:justify-end '>
                                <FormCard details={details} page={'/asf/registered'} promo={promo} title={"Impuestos al día ¡Regístrate ya!👇"} buttonLabel={"Haz clic aquí para empezar"} setSignupLoading={setSignupLoading} signupLoading={signupLoading} />
                            </div>
                            
                                <FormCard details={details} hidden={true} page={'/asf/registered'} promo={promo} title={"Impuestos al día ¡Regístrate ya!👇"} buttonLabel={"Haz clic aquí para empezar"} setSignupLoading={setSignupLoading} signupLoading={signupLoading} />
                            
                        </>
                    </LandingLayout>
                </div>

                <div className={`flex flex-col max-w-7xl mx-auto lg:mt-16 md:mt-0`}>

                    {footerSection()}
                </div>
            </section>
        </>
    );
}

export default Main;