import React, { useCallback, useEffect, useState } from 'react';
import Main from '../Components/Home/Main';
import PricesV2 from '../Components/Regularizacion/PricesV2';
import Opinions from '../Components/Home/Opinions';
import Footer from '../Components/Regularizacion/Footer';
import NaviBar from '../Components/Home/NavBar';
import PortalPopup from '../Components/AsesoriaFiscal/portal-popup';
import Form from "../Components/shared/form";
import { useRouter } from 'next/router';
import cookie from "js-cookie";
import LayoutMeta from '../Components/Hooks/LayoutMeta';
import FixatMediaShow from '../Components/Home/FixatMediaShow';
import dynamic from 'next/dynamic'

//Dynamic Imports
const LearnMore = dynamic(() => import('../Components/Home/LearnMore'))
const DeductTaxes = dynamic(() => import('../Components/Home/DeductTaxes'))
const ByeStress = dynamic(() => import('../Components/Home/ByeStress'))
const OurFormula = dynamic(() => import('../Components/Home/OurFormula'))
const WeAreWithYou = dynamic(() => import('../Components/Regularizacion/WeAreWithYou'))

const Home = () => {
    
    //Declaraciones
    const [popup, setPopup] = useState(false);
    const router = useRouter();
    const paramsCookieName = "fixat_utm_params";
    const [signupLoading, setSignupLoading] = useState(false);
    const [visibility, setVisibility] = useState(true);

    const closeFormPopup1 = useCallback(() => {
        setPopup(false);
    }, []);
    const openFormPopup1 = useCallback(() => {
        setPopup(true);
    }, []);

    useEffect(() => {
        if (Object.keys(router.query).length === 0) return;
        const paramsAsString = router.asPath;
        cookie.set(paramsCookieName, decodeURI(paramsAsString), {
            expires: 1
        });
    }, [router]);


    useEffect(() => {

        if (popup) {
            document.body.className = "overflow-y-hidden"
        }
        else {
            document.body.className = "overflow-y-scroll"
        }

        return () => {
            document.body.className = "overflow-y-scroll"
        }

    }, [popup])


    return (
        <>
            <LayoutMeta title='FIXAT® | Tu contador online para declaración de impuestos' alternateName='Fixat® | Ayudamos a freelancers y emprendedores a declarar y optimizar sus impuestos' description='FIXAT es una plataforma Digital que ayuda a freelancers y emprendedores a optimizar el pago de sus impuestos, realizar sus declaraciones y mantenerlos al corriente con el SAT.' url='https://fixat.mx/' image='https://fixat.mx/_next/image?url=https%3A%2F%2Fcdn.fixat.mx%2Fassets%2Fhome%2Fimg%2Favion-declara-impuestos-sin-complicaciones.png&w=1920&q=75'>
                <main className={` ${popup && 'blur-sm h-fit'}   flex flex-col`}>
                    <NaviBar setVisibility={setVisibility} openFormPopup={openFormPopup1} />
                    <Main visibility={visibility} setIsOpen={openFormPopup1} />
                    <FixatMediaShow media={process.env.MEDIA_MAIN} />
                    <LearnMore />
                    <DeductTaxes />
                    <ByeStress />
                    <PricesV2/>
                    <OurFormula />
                    <Opinions testimonials={process.env.TESTIMONIALS} setIsOpen={setPopup} />
                    <WeAreWithYou setIsOpen={setPopup} />
                    <Footer />

                    {popup && (
                        <PortalPopup
                            overflow="hidden"
                            overlayColor="rgba(0, 0, 0, 0.2)"
                            placement={`Top right`}
                            onOutsideClick={closeFormPopup1}
                        >
                            <Form page={'/asf/registered'} onClose={closeFormPopup1} signupLoading={signupLoading} setSignupLoading={setSignupLoading} title={"Impuestos al día ¡Regístrate ya!👇"} buttonLabel={"Haz clic aquí para empezar"}/>
                        </PortalPopup>
                    )}
                </main>
            </LayoutMeta>

        </>
    );
}

export default Home;