import React, { useState, useEffect, useContext } from "react";
import Image from 'next/image'
import { Transition } from "@headlessui/react";
import { Link } from 'react-scroll';
import { useRouter } from 'next/router'
import Banner from "../Promos/Banner";
import { PromotionContext } from "../../pages/_app";

const NaviBar = ({ openFormPopup, setVisibility }) => {
    let { promo, details } = useContext(PromotionContext);
    const [isOpen, setIsOpen] = useState(false);
    const myRef = React.createRef();
    const url = '/';
    // const [sections, setSections] = useState([]);
    // const [hasHeight, setHasHeight] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            router.push(`/${hash}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        router.push(url);
    }

    const [nav, setNav] = useState([
        { id: 1, link: 'como-funciona', description: '¿Cómo funciona?', current: false },
        { id: 2, link: 'servicios-y-precios', description: 'Precio y servicios', current: false },
        { id: 3, link: 'acerca-de-fixat', description: 'Acerca de FIXAT®', current: false },
        { id: 4, link: 'resenas', description: 'Reseñas', current: false },
        { id: 5, link: 'https://fixat.mx/blog', description: 'Blog', current: false },
        // { id: 5, link: 'home_lets_start', description: 'Ayuda', current: false}
    ])

    // const getSections = () => {
    //     if (!hasHeight) {
    //         setHasHeight(true);
    //     } else {
    //         const sections = nav.map(item => {
    //             const top = document.querySelector(`#${item.link}`) == null ? 0 : document.querySelector(`#${item.link}`).offsetTop - 30;
    //             const bottom = document.querySelector(`#${item.link}`) == null ? 0 : top + document.querySelector(`#${item.link}`).offsetHeight - 30;
    //             return { id: item.id, top: top, bottom: bottom }
    //         });
    //         setSections(sections);
    //     }
    // }

    const handleUpdateCurrentNav = id => {
        const newNavigation = nav.map(item => {
            if (id == item.id) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        })
        setNav(newNavigation)
    }

    // useEffect(() => {
    //     getSections()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [hasHeight]);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const y = window.scrollY;
    //         const sects = sections.filter(item => item.top <= y && item.bottom >= y);
    //         if (sects.length > 0) {
    //             !nav.filter(it => it.id == sects[0].id)[0].current && handleUpdateCurrentNav(sects[0].id);
    //         } else {
    //             nav.some(it => it.current) && handleUpdateCurrentNav(0);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sections, nav]);

    useEffect(() => {
        const link = window.location.hash.slice(2)
        const sect = nav.find(item => item.link == link);
        if (sect) {
            handleUpdateCurrentNav(sect.id);
        } else {
            nav.some(it => it.current) && handleUpdateCurrentNav(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath]);

    const handleNavLinkClick = (hash) => {
        router.push(`#!${hash}`);
    };

    return (
        <div className="w-full fixed  top-0 z-50">
            {(promo && details?.show_banner) && <Banner setVisibility={setVisibility} openFormPopup={openFormPopup} />}
            <nav className="bg-secondary">
                <div className="navbar-container mx-auto" id="header_top">
                    <div className="flex max-w-screen-lg mx-auto xs:justify-around lg:justify-center h-16">
                        <div className="flex w-auto lg:w-full justify-between items-center">
                            <div className="flex-shrink-0 mr-8">
                                <Link rel='follow' href="/" to="/" onClick={handleClick}>
                                    <div className="relative w-[169px] h-[44px]">
                                        <Image loading="lazy"
                                            src='https://cdn.fixat.mx/assets/img/no-webp/logo-principal-fixat.png'
                                            alt="Logo principal de Fixat®"
                                            title="Fixat® | Declaramos tus impuestos"
                                            layout="fill"
                                            objectFit="contain"
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div className="xs:hidden lg:block ">
                                <div className="ml-8 flex items-baseline space-x-4 navbar">
                                    {nav.map(item => (
                                        // <Link rel='follow' key={ item.id } to={ item.link } href="/" className={`${item.current ? 'bg-white/10' : ''} rounded-full transition-colors delay-100 hover:delay-[0ms] hover:bg-white/10 p-3`} smooth={ true }>{ item.description }
                                        // </Link>
                                        <>
                                            {item.description === "Blog" ? (
                                            <a
                                                key={item.id}
                                                href="https://fixat.mx/blog"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="rounded-full transition-colors md:hover:delay-[0ms] md:hover:bg-white/10 p-3"
                                            >
                                                {item.description}
                                            </a>
                                            ) : (
                                            <a
                                                onClick={() => handleNavLinkClick(item.link)}
                                                rel="follow"
                                                key={item.id}
                                                href={`#!${item.link}`}
                                                className={`${
                                                item.current ? "bg-white/10" : ""
                                                } rounded-full transition-colors md:hover:delay-[0ms] md:hover:bg-white/10 p-3`}
                                            >
                                                {item.description}
                                            </a>
                                            )}
                                        </> 
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex xs:block lg:hidden ml-16 -mr-3">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className="bg-transparent inline-flex items-center justify-center p-2 rounded-md text-white-700 text-white md:hover:bg-transparent"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                    <svg
                                        className="block h-12 w-12 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-12 w-12"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-50 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {() => (<>
                        <div ref={myRef} className="xs:block lg:hidden" id="mobile-menu">
                            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                <a rel='follow' href={`/`} className={`visited:bg-white/10 text-white block px-3 py-2 rounded-md text-base font-medium`}>
                                    Inicio
                                </a>
                                {nav.map(item => (
                                    // <Link rel='follow' key={item.id} to={item.link} onClick={() => setIsOpen(false)} href="/" className={`${item.current ? 'bg-white/10' : ''} text-white block px-3 py-2 rounded-md text-base font-medium`} smooth={true}>{item.description}
                                    // </Link>
                                    <>
                                        {item.description === "Blog" ? (
                                            <a
                                                key={item.id}
                                                href="https://fixat.mx/blog"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-white block px-3 py-2 rounded-md text-base font-medium"
                                            >
                                                {item.description}
                                            </a>
                                            ) : (
                                            <a
                                                onClick={() => handleNavLinkClick(item.link)}
                                                rel="follow"
                                                key={item.id}
                                                href={`#!${item.link}`}
                                                className={`${
                                                item.current ? "bg-white/10" : ""
                                                } text-white block px-3 py-2 rounded-md text-base font-medium`}
                                            >
                                                {item.description}
                                            </a>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </>
                    )}
                </Transition>
            </nav>
        </div>
    );
}

export default NaviBar;